import React from "react";

import Header from "./components/header";
import Footer from "./components/footer";

const About = () => {
  return (
    <>
      <Header />
      <main className="bg-gray-200 py-8 space-y-8">
        <div className="mx-auto  max-w-6xl container space-y-4">
          <div className="p-8 bg-white font-playfirDisplay italic text-2xl">
          Новости компании
          </div>
          <div className="p-8 bg-white">
            Раздел дополняется
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default About;
